/* Use this file to define print styles. */
/*------------------------------------------------
- Ici, toutes les fonctions sass et les mixins
------------------------------------------------*/
/*==============================*/
/*Custom Functions*/
/*==============================*/
/*==============================*/
/*Custom Mixins*/
/*==============================*/
.visually-hidden {
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, top);
*/
.full-width {
  position: relative;
  left: 50%;
  width: 100vw;
  max-width: none;
  transform: translateX(-50%);
}
.full-width::before, .full-width::after {
  content: "";
  display: table;
  clear: both;
}

/*
 * vars.scss
 */
/*==============================*/
/*Colors*/
/*==============================*/
/*==============================*/
/*Spacers*/
/*==============================*/
/*==============================*/
/*Fonts*/
/*==============================*/
/* 10px, IE9-IE11 math fixing. See http://bit.ly/1g4X0bX */
/* 10px, IE9-IE11 math fixing. See http://bit.ly/1g4X0bX */
/*==============================*/
/*==============================*/
/*==============================*/
/*Avalanche grid system (http://colourgarden.net/avalanche/)*/
/*==============================*/
/* * {
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}*/
* {
  opacity: 1 !important;
  transform: translate(0, 0) !important;
}

header,
footer {
  display: none !important;
}

.logo-print-pdf {
  display: block !important;
  margin: 20px auto;
}

body,
main,
html,
global-wrapper,
h1,
h2,
h3,
h4,
h5,
like-h1,
like-h2,
like-h3,
like-h4,
like-h5,
p {
  color: #000 !important;
}

.full-width {
  width: 100% !important;
}

.paragraph {
  margin: 0.8rem !important;
}

.bk-newsletter,
.pager,
.bk-connection-bar {
  display: none;
}

.bk-accordion {
  display: block !important;
}
.bk-accordion__list-item-content {
  display: block !important;
}

.grid {
  display: block !important;
}
.grid .grid__cell {
  width: 100% !important;
}

.swiper-slide {
  width: 100% !important;
}

.swiper-pagination-custom {
  display: none !important;
}

.swiper-wrapper {
  display: block !important;
  transform: translate3d(0, 0, 0) !important;
}

.bk-tutorial-tabs p {
  display: block !important;
  overflow: auto !important;
  page-break-before: auto;
  /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
  page-break-after: auto;
  /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
  page-break-inside: avoid;
  /* or 'auto' */
}
.bk-tutorial-tabs .tabs {
  display: none !important;
}
.bk-tutorial-tabs div[class^=tab] {
  display: block !important;
  margin-bottom: 30px !important;
  overflow: auto !important;
  page-break-before: auto;
  /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
  page-break-after: auto;
  /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
  page-break-inside: avoid;
  /* or 'auto' */
}
.bk-tutorial-tabs .tab-title {
  display: block !important;
  font-size: 18px !important;
}
.bk-tutorial-tabs img {
  display: block;
  page-break-before: auto;
  /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
  page-break-after: auto;
  /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
  page-break-inside: avoid;
  /* or 'auto' */
}

.bk-tutorial-contact-form {
  display: none !important;
}
.bk-tutorial-contact-form.-validated {
  display: none !important;
}

.back-to-top {
  display: none !important;
}