/* Use this file to define print styles. */
@import 'utils/mixins';
@import 'base/vars';

//Force backgroud images/color
/* * {
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}*/

* {
    opacity: 1 !important;
    transform: translate(0, 0) !important;
}

header,
footer {
    display: none !important;
}


.logo-print-pdf {
    display: block !important;
    margin: 20px auto;
}

body,
main,
html,
global-wrapper,
h1,
h2,
h3,
h4,
h5,
like-h1,
like-h2,
like-h3,
like-h4,
like-h5,
p {
    color: $black !important;
}

.full-width {
    width: 100% !important;
}

.paragraph {
    margin: rem($spacer-xxs) !important;
}

.bk-newsletter,
.pager,
.bk-connection-bar {
    display: none;
}

.bk-accordion {
    display: block !important;

    &__list-item-content {
        display: block !important;
    }
}

.grid {
    display: block !important;

    // sass-lint:disable-all
    .grid__cell {
        width: 100% !important;
    }
    // sass-lint:enable-all
}

.swiper-slide {
    width: 100% !important;
}

.swiper-pagination-custom {
    display: none !important;
}

.swiper-wrapper {
    display: block !important;
    transform: translate3d(0, 0, 0) !important;
}

.bk-tutorial-tabs {
    p {
        display: block !important;
        overflow: auto !important;
        page-break-before: auto; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
        page-break-after: auto; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
        page-break-inside: avoid; /* or 'auto' */
    }

    .tabs {
        display: none !important;
    }

    div[class^='tab'] {
        display: block !important;
        margin-bottom: 30px !important;
        overflow: auto !important;
        page-break-before: auto; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
        page-break-after: auto; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
        page-break-inside: avoid; /* or 'auto' */
    }


    .tab-title {
        display: block !important;
        font-size: 18px !important;
    }

    img {
        display: block;
        page-break-before: auto; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
        page-break-after: auto; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
        page-break-inside: avoid; /* or 'auto' */
    }
}

.bk-tutorial-contact-form {
    display: none !important;
    &.-validated {
        display: none !important;
    }
}

.back-to-top {
    display: none !important;
}
