/*------------------------------------------------
- Ici, toutes les fonctions sass et les mixins
------------------------------------------------*/

/*==============================*/
/*Custom Functions*/
/*==============================*/
@function rem($px-value, $baseline: $root-font-size) {
    @if $px-value == 0 or type-of($px-value) != 'number' {
        @return $px-value;
    }

    @return $px-value / ($baseline / 1rem);
}

@function em($px-value, $baseline: $root-font-size) {
    @if $px-value == 0 or type-of($px-value) != 'number' {
        @return $px-value;
    }

    @return $px-value / ($baseline / 1em);
}

// sass-lint:disable-all
@mixin font-smoothing() {
    font-smooth: antialiased;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-stroke: 1px transparent;
}

// sass-lint:disable-all

/*==============================*/
/*Custom Mixins*/
/*==============================*/
@mixin clearfix {
    //*zoom: 1;
    &::before, &::after {
        content: '';
        display: table;
        clear: both;
    }
}

@mixin h1 {
    font-size: rem(40px);
    line-height: rem(46px);

    // @include mq($until: tablet) {
    //     font-size: rem(30px);
    // }
}

@mixin h2 {
    font-size: rem(28px);
    line-height: rem(24px);
    // @include mq($until: tablet) {
    //     font-size: rem(30px);
    // }
}

@mixin h3 {
    font-size: rem(24px);
    line-height: rem(30px);
    // @include mq($until: tablet) {
    //     font-size: rem(25px);
    // }
}

@mixin h4 {
    font-size: rem(20px);
    line-height: rem(26px);

    // @include mq($until: tablet) {
    //     font-size: rem(20px);
    // }
}

@mixin h5 {
    font-size: rem(16px);
    line-height: rem(22px);

    // @include mq($until: tablet) {
    //     font-size: rem(20px);
    // }
}

// @mixin h6 {
//     font-size: rem(16px);

//     @include mq($until: tablet) {
//         font-size: rem(20px);
//     }
// }

//For @extend
.visually-hidden {
    margin: -1px;
    padding: 0;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    position: absolute;
}

@mixin last-child-no-margin {
    > *:last-child {
        margin-bottom: 0
    }
}

@mixin first-of-type-no-margin-top {
    &:first-child {
        margin-top: 0;
    }
}

@mixin break-word {
    -ms-word-break: break-all;
    word-break: break-all;

    /* Non standard for WebKit */
    word-break: break-word;

    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
}

@mixin bk-type ($margin-b: $spacer-m) {

    @include last-child-no-margin;

    @if ($margin-b=="m") {
        margin-bottom: rem(40px);

        @include mq($until: tablet) {
            margin-bottom: rem(30px);
        }
    }

    @else if ($margin-b=="l") {
        margin-bottom: rem(60px);

        @include mq($until: tablet) {
            margin-bottom: rem(40px);
        }
    }

    @else if ($margin-b=="xl") {
        margin-bottom: rem(80px);

        @include mq($until: tablet) {
            margin-bottom: rem(50px);
        }
    }

    @else {
        margin-bottom: rem($margin-b);
    }
}

@mixin bk-type-t ($margin-t: $spacer-24) {

    @include last-child-no-margin;

    @if ($margin-t=="m") {
        margin-top: rem($spacer-m);

        @include mq($until: tablet) {
            margin-top: rem($spacer-s);
        }
    }

    @else if ($margin-t=="l") {
        margin-top: rem($spacer-l);

        @include mq($until: tablet) {
            margin-top: rem($spacer-m);
        }
    }

    @else if ($margin-t=="xl") {
        margin-top: rem($spacer-xl);

        @include mq($until: tablet) {
            margin-top: rem($spacer-l);
        }
    }

    @else {
        margin-top: rem($margin-t);
    }
}

@mixin font-style($style) {
    font-weight: 400;
    font-style: normal;
    @if ($style == 'regular') {
        font-family: $default-fonts;
    } @else if ($style == 'light') {
        font-weight: 300;
    } @else if ($style == 'medium') {
        font-weight: 500;
    } @else if ($style == 'bold') {
        // font-family: 'DM sans', Arial, sans-serif;
        font-weight: 700;
    } @else if ($style == 'black') {
        font-family: 'DM sans', Arial, sans-serif;
        font-weight: normal;
    }
}

@mixin icomoon {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: '#{$icomoon-font-family}' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

// Mobile first way
@mixin bp($min:'', $max:'') {
    $query: true;
    @if ($min == '') {
        @media only screen and (max-width: rem($max + $scrollbar-width, 16px)) {
            @content;
        }
    } @else if ($max == '') {
        @media only screen and (min-width: rem($min + $scrollbar-width, 16px)) {
            @content;
        }
    } @else {
        @media only screen and (min-width: rem($min + $scrollbar-width, 16px)) and (max-width: rem($max + $scrollbar-width, 16px)) {
            @content;
        }
    }
    $query: false;
}

// @mixin large($min:'') {
//     @include breakpoint($large - 1, $min) {
//         @content;
//     }
// }

// @mixin medium($min:'') {
//     @include breakpoint($medium - 1, $min) {
//         @content;
//     }
// }

@mixin tablet($min:'') {
    @include breakpoint($tablet - 1, $min) {
        @content;
    }
}

@mixin mobile($min:'') {
    @include breakpoint($mobile - 1, $min) {
        @content;
    }
}

/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, top);
*/
@mixin object-fit($fit: fill, $position: null) {
    -o-object-fit: $fit;
    object-fit: $fit;

    @if $position {
        -o-object-position: $position;
        object-position: $position;
        font-family: 'object-fit: #{$fit}; object-position: #{$position}';
    }

    @else {
        font-family: 'object-fit: #{$fit}';
    }
}

//animation mixins
@mixin hover-moving-underline($height: 1px, $offset: 0px, $bottom: 0) {
    $offsetx2: $offset * 2;
    position: relative;
    text-decoration: none;
    &::after {
        content: '';
        position: absolute;
        left: #{$offset};
        width: calc(100% - #{$offsetx2});
        transform: scaleX(0) translateZ(0);
        transform-origin: 100% 100%;
        bottom: $bottom;
        border-bottom: #{$height} solid $color-primary;
        transition: transform .5s cubic-bezier(.215,.61,.355,1);
    }
    &:hover,
    &:focus {
        &::after {
            transform: scaleX(1) translateZ(0);
            transform-origin: 0 0;
        }
    }
}

@mixin full-width {
    position: relative;
    left: 50%;
    width: 100vw;
    max-width: none;
    transform: translateX(-50%);
}

.full-width {
    @include clearfix;
    @include full-width;
}

@mixin card-box-shadow($width: 10px, $opacity: .2) {
    box-shadow: 0 0 rem($width) rgba($black, $opacity);
}

@mixin dark-bg() {
    #{$dark-backgrounds} {
        @media not print {
            @content
        }
    }
}

@mixin scrollbar($size) {

    &::-webkit-scrollbar {
        width: $size;
        height: $size;
        background-color: rgba(0,0,0,0);
        -webkit-border-radius: 100px;
    }

    &::-webkit-scrollbar:hover {
        background-color: rgba(0, 0, 0, 0.09);
    }

    &::-webkit-scrollbar-thumb {
        background: rgba(0,0,0,0.5);
        -webkit-border-radius: 100px;
    }
    &::-webkit-scrollbar-thumb:active {
        background: rgba(0,0,0,0.61); /* Some darker color when you click it */
        -webkit-border-radius: 100px;
    }

    /* add vertical min-height & horizontal min-width */
    &::-webkit-scrollbar-thumb:vertical {
        min-height: $size;
    }

    &::-webkit-scrollbar-thumb:horizontal {
        min-width: $size;
    }
}
